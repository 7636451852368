.btn {
  @apply inline-block py-2 px-3.5 text-center align-middle rounded transition-colors;

  font-size: theme('fontSize.base');
  color: var(--btn-color, currentColor);
  background-color: var(--btn-bg, transparent);
  border: 1px solid var(--btn-border-color, transparent);

  &:disabled {
    opacity: 0.65;
  }

  &:not(:disabled) {
    &:hover,
    &:active {
      color: var(--btn-hover-color);
      background-color: var(--btn-hover-bg);
      border-color: var(--btn-hover-border-color);
    }
  }

  &-primary {
    --btn-color: theme(colors.white);
    --btn-bg: theme(colors.primary.DEFAULT);
    --btn-border-color: theme(colors.primary.DEFAULT);
    --btn-hover-color: theme(colors.white);
    --btn-hover-bg: theme(colors.primary.600);
    --btn-hover-border-color: theme(colors.primary.600);
  }

  &-secondary {
    --btn-color: theme(colors.black);
    --btn-bg: theme(colors.secondary.DEFAULT);
    --btn-border-color: theme(colors.secondary.DEFAULT);
    --btn-hover-color: theme(colors.black);
    --btn-hover-bg: theme(colors.secondary.600);
    --btn-hover-border-color: theme(colors.secondary.600);
  }

  &-outline-primary {
    --btn-color: theme(colors.primary.DEFAULT);
    --btn-border-color: theme(colors.primary.DEFAULT);
    --btn-hover-color: theme(colors.white);
    --btn-hover-bg: theme(colors.primary.DEFAULT);
    --btn-hover-border-color: theme(colors.primary.DEFAULT);
  }

  &-sm {
    @apply py-1 px-2 rounded-sm;

    font-size: theme(fontSize.sm);

    .icon {
      font-size: 0.8125rem;
    }
  }

  &-lg {
    @apply px-4;

    font-size: theme(fontSize.xl);
  }
}
