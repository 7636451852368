.alert {
  @apply flex justify-between items-start mb-4 p-4 rounded;

  color: theme(colors.black / 90%);
  background-color: var(--alert-bg, transparent);

  &-success {
    --alert-bg: theme(colors.success.400);
  }

  &-info {
    --alert-bg: theme(colors.info.400);
  }

  &-warning {
    --alert-bg: theme(colors.warning.400);
  }

  &-error {
    --alert-bg: theme(colors.error.400);
  }
}
