@layer components {
  .icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: -0.125em;
    fill: currentcolor;

    &-lg {
      font-size: theme('fontSize.lg');
    }

    &-xl {
      font-size: theme('fontSize.xl');
    }

    &-2xl {
      font-size: theme('fontSize.2xl');
    }
  }
}
