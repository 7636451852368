@import 'tailwindcss/base';
@import './base/forms';
@import './base/typography';

@import 'tailwindcss/components';
@import './components/alert';
@import './components/button';
@import './components/icon';

@import 'tailwindcss/utilities';
