body {
  font-family: theme(fontFamily.body);
  color: theme(colors.primary.800);
  background-color: #fafafa;
}

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading font-light;
  }

  a:focus,
  button:focus {
    @apply outline outline-2;

    outline-color: theme(colors.primary.DEFAULT / 50%);
  }

  .prose {
    font-size: 1rem;
    line-height: 1.5;
  }

  .prose-lg {
    font-size: 1.125rem;
    line-height: 1.5;
  }
}

.prose {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(.not-prose) {
      margin-bottom: 0.5em;
      font-weight: theme(fontWeight.bold);
      line-height: 1.2;
    }
  }

  h1 {
    &:not(.not-prose) {
      font-size: 2.25em;
    }
  }

  h2 {
    &:not(.not-prose) {
      font-size: 1.75em;
    }
  }

  h3 {
    &:not(.not-prose) {
      font-size: 1.5em;
    }
  }

  h4 {
    &:not(.not-prose) {
      font-size: 1.25em;
    }
  }

  h5 {
    &:not(.not-prose) {
      font-size: 1.125em;
    }
  }

  h6 {
    &:not(.not-prose) {
      font-size: 1em;
    }
  }

  a {
    &:not(.not-prose, .btn) {
      @apply underline underline-offset-2;

      opacity: 0.85;

      &:hover {
        opacity: 1;
      }
    }
  }

  p,
  ol,
  ul {
    &:not(.not-prose) {
      font-size: theme(fontSize.base);

      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }

  ol,
  ul {
    &:not(.not-prose) {
      padding-left: 1.625em;

      > li:not(:last-child) {
        margin-bottom: 0.125em;
      }
    }
  }

  ol {
    &:not(.not-prose) {
      list-style: decimal;
    }
  }

  ul {
    &:not(.not-prose) {
      list-style: disc;
    }
  }

  figure {
    &:not(.not-prose) {
      text-align: center;

      &:not(:first-child) {
        margin-top: 2em;
      }

      &:not(:last-child) {
        margin-bottom: 2em;
      }

      img {
        display: inline-block;
      }

      figcaption {
        margin-top: 0.5em;
        color: theme(colors.gray.700);
        font-size: 0.875em;
      }

      @media screen(sm) {
        margin-left: 1em;
        margin-right: 1em;
      }
    }
  }

  .btn {
    &:not(.not-prose) {
      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }
}
